import React, { useState, useLayoutEffect } from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import {
  NameType,
  ValueType
} from 'recharts/types/component/DefaultTooltipContent';
import { format, parseISO } from 'date-fns';
import { sharedLineStyle, SALES_BY_TIME_COLOR } from '../../constants/charts';
import { OrderData } from 'types/analytics';

interface ChartDataPoint {
  hour: number;
  sales: number;
}

interface ChartTimeOfDayProps {
  chartData: OrderData[];
}

const ChartTimeOfDay: React.FC<ChartTimeOfDayProps> = ({ chartData }) => {
  const graphColor = SALES_BY_TIME_COLOR;

  const CustomTooltip = ({
    active,
    payload,
    label
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      const date = new Date(2024, 0, 1, Number(label) || 0);
      const formattedTime = format(date, 'h a');

      return (
        <div className='custom-tooltip'>
          <span className='label'>{formattedTime}</span>
          {payload[0].value} sales
        </div>
      );
    }
    return null;
  };

  const [timeOfDayData, setTimeOfDay] = useState<ChartDataPoint[]>([]);

  useLayoutEffect(() => {
    const data = processOrderFeed(chartData);
    setTimeOfDay(data);
  }, [chartData]);

  const processOrderFeed = (orders: OrderData[]): ChartDataPoint[] => {
    const salesByHour = Array(24).fill(0);

    orders.forEach((order) => {
      const orderDate = parseISO(order.order_date);
      const orderHour = orderDate.getHours(); // current hour (0-23) in local time
      salesByHour[orderHour] += 1;
    });

    const formattedData = salesByHour.map((sales, hour) => ({
      hour,
      sales
    }));

    return formattedData;
  };

  return (
    <ResponsiveContainer height={300} width='100%'>
      <ComposedChart
        data={timeOfDayData}
        margin={{ top: 30, right: 0, left: 0, bottom: 30 }}
      >
        <XAxis
          dataKey='hour'
          label={{
            value: 'Time of Day',
            position: 'bottom',
            offset: 15,
            fill: 'black',
            fontSize: 14
          }}
          tick={{ fill: 'black' }}
          tickLine={false}
          interval={0}
          dy={10}
          {...sharedLineStyle}
        />
        <YAxis
          yAxisId='left'
          orientation='left'
          tick={{ fill: 'black' }}
          axisLine={true}
          tickLine={false}
          {...sharedLineStyle}
          domain={[0, 'dataMax']}
        />
        <YAxis
          yAxisId='right'
          orientation='right'
          tick={{ fill: 'transparent' }}
          axisLine={true}
          tickLine={false}
          hide={false}
          {...sharedLineStyle}
          width={7}
        />
        <CartesianGrid vertical={false} />
        <Tooltip content={CustomTooltip} cursor={false} />
        <Area
          type='monotone'
          dataKey='sales'
          stroke={graphColor}
          strokeWidth={2}
          fillOpacity={0.1}
          fill={graphColor}
          yAxisId='left' // Specify which Y-axis the data is associated with
        />
        <Line
          type='monotone'
          dataKey='sales'
          stroke={graphColor}
          strokeWidth={0}
          fillOpacity={0.1}
          yAxisId='right' // Specify which Y-axis the data is associated with
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ChartTimeOfDay;
