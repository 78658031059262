import { useState } from 'react';
import {
  ComposedChart,
  Area,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  Label
} from 'recharts';
import {
  sharedLineStyle,
  TOTAL_PROFIT_COLOR,
  UNITS_SOLD_COLOR
  // VIEWS_COLOR
} from '../../constants/charts';
import { format, parseISO, isFirstDayOfMonth } from 'date-fns';

/* eslint-disable react/prop-types */
const ChartTotalProfit = ({ chartData }) => {
  const [activeSeries, setActiveSeries] = useState('profitAsNumber');
  const handleLegendClick = (event) => {
    const { dataKey } = event;
    setActiveSeries(dataKey);
  };

  // Format the Xaxis date tick
  const tickFormatter = (date, index) => {
    const parsedDate = parseISO(date);
    // Show month for first tick, first of month, or last tick
    if (index === 0 || isFirstDayOfMonth(parsedDate)) {
      return format(parsedDate, 'MMM');
    }

    // Show day number for other ticks
    return format(parsedDate, 'd');
  };

  const customNames = {
    profitAsNumber: 'Total Profit',
    units: 'Units Sold'
    // views: 'Views'
  };

  // Custom legend with outlined rectangles, where clicked rectangle is filled with color and corresponding graph is displayed as area graph
  const CustomLegend = (props) => {
    const { payload, onClick } = props;
    const handleLegendItemClick = (dataKey) => {
      onClick({ dataKey });
    };
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {payload.map((entry) => (
          <div
            key={entry.dataKey || entry.value}
            style={{
              marginRight: 20,
              display: 'flex',
              alignItems: 'center',
              color: '#080808'
            }}
            onClick={() => handleLegendItemClick(entry.dataKey)}
          >
            <div
              style={{
                opacity: entry.dataKey === activeSeries ? 1 : 0.5,
                width: 32,
                height: 8,
                border: `1px solid ${entry.color}`,
                borderRadius: 2,
                marginRight: 5
              }}
            >
              <div
                style={{
                  opacity: 0.2,
                  width: '100%',
                  height: '100%',
                  backgroundColor:
                    entry.dataKey === activeSeries ? entry.color : 'transparent'
                }}
              />
            </div>
            <span
              style={{
                fontWeight: entry.dataKey === activeSeries ? 500 : 400
              }}
            >
              {customNames[entry.dataKey] || entry.value}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // get the active series data
      const activePayload = payload.filter(
        (item) => item.dataKey === activeSeries
      );

      if (activePayload.length > 0) {
        return (
          <div className='custom-tooltip' role='tooltip'>
            <span className='label'>{`${label}`}</span>
            {`${activeSeries === 'profitAsNumber' ? '$' : ''} ${
              activePayload[0].value
            }`}
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div className='chart'>
      <div className='title'>
        <h3>Profit Over Time</h3>
        {/* // TO DO: set interval based on date range
        <ToggleSelect
          labels={[
            { value: '6', label: 'Week', selected: interval === 6 },
            { value: '30', label: 'Month', selected: interval == 30}
          ]}
          onChange={handleIntervalChange} 
          className='custom-toggle'
          togglePosition='left'
        /> */}
      </div>
      <ResponsiveContainer id='springBoardChart' width='100%' height='100%'>
        <ComposedChart
          width={730}
          height={250}
          data={chartData}
          margin={{
            top: 15,
            right: 0,
            bottom: 15,
            left: 0
          }}
        >
          <XAxis
            dataKey='date'
            tickLine={false}
            tickFormatter={(date, index) => tickFormatter(date, index)}
            tick={{ fill: 'black' }}
            {...sharedLineStyle}
          />
          <YAxis
            yAxisId='left'
            orientation='left'
            tickLine={false}
            dataKey='profitAsNumber'
            tick={{ fill: 'black' }}
            width={50}
            {...sharedLineStyle}
          >
            <Label
              value='$'
              position='top'
              offset={-5}
              style={{
                fill: 'black'
              }}
              dx={-15}
            />
          </YAxis>
          <YAxis
            orientation='right'
            tick={{ fill: 'transparent' }}
            axisLine={true}
            tickLine={false}
            hide={false}
            width={5}
            {...sharedLineStyle}
          />
          <Tooltip
            payload={activeSeries}
            content={<CustomTooltip />}
            cursor={false}
          />
          <CartesianGrid vertical={false} {...sharedLineStyle} />
          <Area
            type='monotone'
            dataKey='profitAsNumber'
            stroke={TOTAL_PROFIT_COLOR}
            opacity={activeSeries.includes('profitAsNumber') ? 1 : 0.6}
            strokeWidth={activeSeries.includes('profitAsNumber') ? 2 : 1}
            fillOpacity={activeSeries.includes('profitAsNumber') ? 0.1 : 0}
            fill={
              activeSeries.includes('profitAsNumber')
                ? TOTAL_PROFIT_COLOR
                : 'transparent'
            }
            activeDot={
              activeSeries.includes('profitAsNumber') ? { r: 4 } : false
            }
          />
          <Area
            type='monotone'
            dataKey='units'
            activeDot={activeSeries.includes('units') ? { r: 4 } : false}
            stroke={UNITS_SOLD_COLOR}
            opacity={activeSeries.includes('units') ? 1 : 0.8}
            strokeWidth={activeSeries.includes('units') ? 2 : 1}
            fillOpacity={activeSeries.includes('units') ? 0.15 : 0}
            fill={
              activeSeries.includes('units') ? UNITS_SOLD_COLOR : 'transparent'
            }
          />
          {/* Hide views until we have data */}
          {/* <Area
            type='monotone'
            dataKey='views'
            activeDot={activeSeries.includes('views') ? { r: 4 } : false}
            stroke={VIEWS_COLOR}
            strokeWidth={activeSeries.includes('views') ? 2 : 1}
            fillOpacity={activeSeries.includes('views') ? 0.15 : 0}
            fill={activeSeries.includes('units') ? VIEWS_COLOR : 'transparent'}
          /> */}
          <Legend
            layout='horizontal'
            verticalAlign='top'
            align='center'
            content={<CustomLegend />}
            wrapperStyle={{ paddingBottom: '20px', top: '-20px' }}
            onClick={(event) => handleLegendClick(event)}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartTotalProfit;
