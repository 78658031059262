import React from 'react';
import {
  BarChart,
  Bar,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Cell
} from 'recharts';
import { sharedLineStyle, SALES_BY_TIME_COLOR } from '../../constants/charts';
import { OrderData } from 'types/analytics';
import * as analyticsCalculations from '../../lib/analyticsCalculations';

interface SalesByWeekdayData {
  day: string;
  sales: number;
}

interface ChartDayOfWeekProps {
  chartData: OrderData[];
}

const ChartDayOfWeek: React.FC<ChartDayOfWeekProps> = ({ chartData }) => {
  const graphColor = SALES_BY_TIME_COLOR;

  // Sales data by day of the week
  const salesByWeekdayData: SalesByWeekdayData[] =
    analyticsCalculations.salesByDayOfWeek(chartData);

  // Sort the data by sales to set opacity
  const sortedData = [...salesByWeekdayData].sort((a, b) => b.sales - a.sales);

  // Calculate opacity based on sorted sales data
  const getOpacity = (sales: number): number => {
    const rank = sortedData.findIndex((d) => d.sales === sales);
    let opacity = 1 - rank * 0.2; // Adjust opacity based on the rank
    opacity = opacity < 0.2 ? 0.2 : opacity; // Ensure opacity doesn't go below 0.2
    return opacity;
  };

  return (
    <ResponsiveContainer height={300} className='small'>
      <BarChart
        data={salesByWeekdayData}
        margin={{ top: 30, right: 0, left: 0, bottom: 30 }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey='day'
          tickLine={false}
          label={{
            value: 'Day of Week',
            position: 'bottom',
            offset: 10,
            fill: 'black',
            fontSize: 14
          }}
          dy={10}
          tick={{ fill: 'black' }}
          {...sharedLineStyle}
        />
        <YAxis
          orientation='left'
          tickLine={false}
          width={40}
          tick={{ fill: 'black' }}
          {...sharedLineStyle}
        />
        <YAxis
          orientation='right'
          tick={false}
          {...sharedLineStyle}
          width={5}
        />
        <Tooltip />
        <Bar dataKey='sales' fill={graphColor}>
          {salesByWeekdayData.map((entry) => (
            <Cell
              key={`${entry.day}-${entry.sales}`}
              fill={graphColor}
              opacity={getOpacity(entry.sales)} // Dynamic opacity
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ChartDayOfWeek;
