import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import AnalyticsSummary from 'components/AnalyticsSummary';
import AnalyticsListingTable from '../../components/AnalyticsListingTable';
import DateRangeSelector from 'components/DateRangeSelector';
import ChartTotalProfit from 'components/ChartTotalProfit';
import ChartDayOfWeek from 'components/ChartDayOfWeek';
import ChartTimeOfDay from 'components/ChartTimeOfDay';
import ChartProductType from 'components/ChartProductType';
import ChartMostPurchasedProduct from 'components/ChartMostPurchasedProduct';
import { fetchAnalyticsGraphql } from '../../redux/actions/analytics';
import './AnalyticsPage.scss';

const Analytics = () => {
  const [range, setRange] = useState('');
  const analytics = useSelector((state) => state.analytics);
  const dispatch = useDispatch();

  useEffect(() => {
    if (range.startDate && range.endDate) {
      const getUpdatedAnalyticsData = async () => {
        await dispatch(
          fetchAnalyticsGraphql(
            format(range.startDate, 'yyyy-MM-dd'),
            format(range.endDate, 'yyyy-MM-dd')
          )
        );
      };
      getUpdatedAnalyticsData();
    }
  }, [range, dispatch]);

  return (
    <div className='analytics'>
      <div className='overview__content'>
        <div className='title'>
          <h1>Analytics</h1>
          <DateRangeSelector setRange={setRange} />
        </div>
        <AnalyticsSummary data={analytics} />
        <div className='content'>
          <ChartTotalProfit chartData={analytics?.stats?.chart_data || []} />
        </div>
        <div className='content'>
          <AnalyticsListingTable
            data={analytics?.stats?.campaign_roots_data || []}
            isFetching={analytics.isFetching}
          />
        </div>
        <div className='content no-padding'>
          <div className='chart-wrapper'>
            <div className='one-of-two'>
              <div className='title'>
                <h3>Most Purchased Product</h3>
              </div>
              <ChartMostPurchasedProduct
                chartData={analytics?.stats?.order_feed || []}
              />
            </div>
            <div className='one-of-two'>
              <div className='title'>
                <h3>Most Purchased Category</h3>
              </div>
              <ChartProductType
                chartData={analytics?.stats?.order_feed || '--'}
              />
            </div>
          </div>
        </div>
        <div className='content'>
          <div className='title'>
            <h3>Sales by Time</h3>
          </div>
          {/* bar by day of the week */}
          <div className='chart-container'>
            <ChartDayOfWeek chartData={analytics?.stats?.order_feed || []} />
            {/* line area by time of day */}
            <ChartTimeOfDay chartData={analytics?.stats?.order_feed || []} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
