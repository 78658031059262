import PropTypes from 'prop-types';
import './PromoBanner.scss';

const PromoBanner = ({ banners }) => {
  return (
    <section data-testid='1234'>
      {banners?.map((banner) =>
        banner.link ? (
          <a
            id={banner.id}
            key={banner.id}
            href={banner.link}
            target='_blank'
            rel='noopener noreferrer'
          >
            <picture className='promo_banner'>
              <source srcSet={banner.mobileSrc} media='(max-width: 1039px)' />
              <source srcSet={banner.desktopSrc} media='(min-width:1040px)' />
              <img
                className='promo_banner__image'
                src={banner.desktopSrc}
                alt={banner.alt}
              />
            </picture>
          </a>
        ) : (
          <div id={banner.id} key={banner.id}>
            <picture className='promo_banner'>
              <source srcSet={banner.mobileSrc} media='(max-width: 1039px)' />
              <source srcSet={banner.desktopSrc} media='(min-width:1040px)' />
              <img
                className='promo_banner__image'
                src={banner.desktopSrc}
                alt={banner.alt}
              />
            </picture>
          </div>
        )
      )}
    </section>
  );
};

// PropTypes for type checking
PromoBanner.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      desktopSrc: PropTypes.string.isRequired,
      mobileSrc: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  ).isRequired
};

export default PromoBanner;
