import React, { useState } from 'react';
import './ToggleSelect.scss';

export interface Label {
  value: string;
  label: string;
  selected?: boolean;
  onSelect?: () => void;
  disabled?: boolean;
}

interface ToggleSelectProps {
  labels: Label[]; // Array of toggle options
  onSelect?: (label: Label) => void;
  className?: string;
  style?: React.CSSProperties;
  togglePosition?: 'left' | 'right' | 'center';
}

export const ToggleSelect: React.FC<ToggleSelectProps> = ({
  labels = [],
  onSelect,
  className = '',
  style = {},
  togglePosition = 'center'
}) => {
  const [selectedLabel, setSelectedLabel] = useState<Label>(
    labels?.find((label) => label.selected) ?? labels[0]
  );

  const handleSelect = (label: Label) => {
    if (label.disabled) return; // Prevent interaction if disabled
    setSelectedLabel(label);
    onSelect?.(label);
    label.onSelect?.();
  };

  const getToggleIndex = () => {
    const selectedIndex = labels.findIndex(
      (label) => label.value === selectedLabel?.value
    );
    return selectedIndex >= 0 ? selectedIndex : 0;
  };

  const renderLabel = (label: Label) => (
    <button
      key={label.value}
      className={`toggle-select__label ${
        label.value === selectedLabel?.value ? 'selected' : 'unselected'
      } ${label.disabled ? 'disabled' : ''}`}
      onClick={() => handleSelect(label)}
      disabled={label.disabled}
      aria-pressed={label.value === selectedLabel?.value}
    >
      {label.label}
    </button>
  );

  if (!labels || labels.length === 0) {
    return null; // Do not render if labels array is undefined or empty
  }

  return (
    <div
      className={`toggle-select ${className}`}
      style={
        {
          '--num-labels': labels.length,
          '--toggle-position': togglePosition,
          ...style
        } as React.CSSProperties
      }
      role='radiogroup'
    >
      <div
        className='toggle-select__toggle'
        style={{ '--index': getToggleIndex() } as React.CSSProperties}
      />
      {labels.map(renderLabel)}
    </div>
  );
};

export default ToggleSelect;
