import React from 'react';
import bag from '../../assets/bag.svg';
import card from '../../assets/card.svg';
import tag from '../../assets/tag.svg';
// import view from '../../assets/view.svg';

interface Stats {
  profit_made: { amount: string };
  order_units: number;
  orders: number;
  views: number | null;
}

interface Data {
  isFetching: boolean;
  stats: Stats;
}

interface AnalyticsSummaryProps {
  data: Data;
}

const AnalyticsSummary: React.FC<AnalyticsSummaryProps> = ({ data }) => {
  return (
    <div className='summary'>
      <div className='item'>
        <span className='icon'>
          <img src={card} alt='card icon' />
        </span>
        <p className='number'>
          {data.isFetching ? '--' : data.stats.profit_made.amount}
        </p>
        <span className='label'>Total Profit</span>
      </div>
      <div className='item'>
        <span className='icon'>
          <img src={tag} alt='tag icon' />
        </span>
        <p className='number'>
          {data.isFetching ? '--' : data.stats.order_units}
        </p>
        <span className='label'>Units Sold</span>
      </div>
      <div className='item'>
        <span className='icon'>
          <img src={bag} alt='bag icon' />
        </span>
        <p className='number'>{data.isFetching ? '--' : data.stats.orders}</p>
        <span className='label'>Orders Placed</span>
      </div>
      {/* Hide Views until we have the data */}
      {/* <div className='item'>
        <span className='icon'>
          <img src={view} alt='view icon' />
        </span>
        <p className='number'>
          {data.isFetching || !data.stats.views ? '--' : data.stats.views}
        </p>
        <span className='label'>Views</span>
      </div> */}
    </div>
  );
};

export default AnalyticsSummary;
