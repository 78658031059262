import { useMemo } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { calculateProductCounts } from '../../lib/analyticsCalculations';
import { PRODUCT_TYPE_COLORS } from '../../constants/charts';
import PropTypes from 'prop-types';
import tshirt from '../../assets/t_shirt.svg';

// TO DO: get product types(groups?)
// as of Nov 28, 2024 this component uses mock data for product types
const ChartProductType = ({ chartData }) => {
  // Map product keywords to their respective types
  const productTypes = {
    Sweatshirt: 'Sweatshirt',
    Tee: 'Tee',
    Mug: 'Mug',
    Pouch: 'Pouch',
    Hoodie: 'Hoodie',
    Backpack: 'Backpack',
    Tumbler: 'Tumbler',
    Notebook: 'Notebook',
    Sticker: 'Sticker'
  };

  // Function to determine product type from name
  const getProductType = (name) => {
    for (const key in productTypes) {
      if (Object.hasOwn(productTypes, key) && name.includes(key)) {
        return productTypes[key];
      }
    }
    return 'Unknown';
  };

  // Convert to an array format
  const productCountsArray = Object.entries(
    calculateProductCounts(chartData, getProductType)
  ).map(([type, count]) => ({
    type,
    count
  }));

  // Sort data to get top 3 products
  const top3ProductData = productCountsArray
    .sort((a, b) => b.count - a.count)
    .slice(0, 3);
  // Find the most purchased product
  const mostPurchasedProduct = top3ProductData[0] || { type: 'None', count: 0 };

  const CustomLegend = useMemo(
    () => (props) => {
      const { payload } = props;
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {payload.map((entry, index) => (
            <div
              key={entry.dataKey || entry.value}
              style={{
                marginRight: 20,
                display: 'flex',
                alignItems: 'center',
                color: '#080808'
              }}
            >
              <div
                style={{
                  opacity: 1,
                  width: 32,
                  height: 8,
                  borderRadius: 2,
                  marginRight: 5,
                  backgroundColor:
                    PRODUCT_TYPE_COLORS[index % PRODUCT_TYPE_COLORS.length]
                }}
              />
              <span
                style={{
                  fontWeight: 500
                }}
              >
                {entry.value}
              </span>
            </div>
          ))}
        </div>
      );
    },
    []
  );

  return (
    <PieChart width={300} height={300} style={{ margin: '20px auto 20px' }}>
      <Pie
        data={top3ProductData}
        dataKey='count'
        nameKey='type'
        innerRadius={70} // Creates the doughnut effect
        outerRadius={120}
      >
        {top3ProductData.map((entry, index) => (
          <Cell
            key={`cell-${entry}`}
            fill={PRODUCT_TYPE_COLORS[index % PRODUCT_TYPE_COLORS.length]}
          />
        ))}
      </Pie>
      <Tooltip />
      <Legend
        verticalAlign='bottom'
        wrapperStyle={{ paddingTop: '20px' }}
        height={36}
        content={<CustomLegend />}
      />
      <image
        x='50%' // Center the image horizontally
        y={100} // Adjust to place above the text
        width='40'
        height='16'
        href={tshirt}
        transform='translate(-20, 0)' // Adjust for centering
      />
      <text
        x='50%'
        y={140}
        textAnchor='middle'
        dominantBaseline='middle'
        style={{ fontSize: '28px', fontWeight: 'bold' }}
      >
        {mostPurchasedProduct.count}
      </text>
      <text
        x='50%'
        y={165}
        textAnchor='middle'
        dominantBaseline='middle'
        style={{ fontSize: '12px' }}
      >
        {mostPurchasedProduct.type}
      </text>
    </PieChart>
  );
};

ChartProductType.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      line_items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          quantity: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired
};

export default ChartProductType;
