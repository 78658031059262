import { parseISO, format } from 'date-fns';
import { OrderFeed } from 'types/analytics';

type DayOfWeek =
  | 'Sunday'
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday';

// Function to get the day of the week from a date string
export const getDayOfWeek = (dateString: string): DayOfWeek => {
  const date = parseISO(dateString);
  return format(date, 'EEEE') as DayOfWeek; // returns "Monday", "Tuesday", etc.
};

// Aggregate sales by day of the week
export const salesByDayOfWeek = (
  data: OrderFeed
): { day: string; sales: number }[] => {
  // Initialize sales data with all days of the week set to zero
  const daysOfWeek: DayOfWeek[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];

  const salesData = daysOfWeek.reduce<Record<string, number>>((acc, day) => {
    acc[day] = 0; // Initialize each day to 0 sales
    return acc;
  }, {});

  // Aggregate sales data
  data.forEach((item) => {
    try {
      const dayOfWeek = getDayOfWeek(item.order_date);
      const profit = parseFloat(item.net_profit.replace('$', ''));
      if (!isNaN(profit)) {
        salesData[dayOfWeek] += profit;
      }
    } catch {
      // Skip invalid entries
    }
  });

  // Convert the aggregated sales data into an array format
  const formattedData = daysOfWeek.map((day) => ({
    day: day.charAt(0), // Use only the first letter of each day
    sales: parseFloat(salesData[day].toFixed(2)) // Ensure two decimal places
  }));

  return formattedData;
};

// Reusable function to calculate product counts
export const calculateProductCounts = (
  orderFeed: OrderFeed,
  groupBy: (name: string) => string
): Record<string, number> => {
  return orderFeed.reduce<Record<string, number>>((acc, order) => {
    order.line_items.forEach((item) => {
      const key = groupBy(item.name); // Use the provided callback
      acc[key] = (acc[key] || 0) + item.quantity;
    });
    return acc;
  }, {});
};

export const parseProfit = (profit: string): number => {
  return parseFloat(profit.replace(/[$,]/g, ''));
};
