import { BarChart, Bar, XAxis, YAxis, LabelList } from 'recharts';
import { calculateProductCounts } from '../../lib/analyticsCalculations';
import PropTypes from 'prop-types';

// TO DO: use product id instead of product name for calculations

// Helper function to strip size from product name.
const getProductNameWithoutSize = (name) => {
  return name.replace(/\s\([^)]*\)/, ''); // Removes any text within parentheses, including the parentheses
};

// Product name label to appear above its corresponding bar
const renderCustomLabel = ({ x, y, value }) => {
  return (
    <text
      x={x} // Align with the start of the bar
      y={y - 10} // Position the label above the bar
      textAnchor='start' // Align text to the left
      fill='#000000'
      fontSize={14}
    >
      {value}
    </text>
  );
};

const ChartMostPurchasedProduct = ({ chartData }) => {
  // Convert to an array and sort to get top 4 products
  const productCountsArray = Object.entries(
    calculateProductCounts(chartData, getProductNameWithoutSize)
  )
    .map(([name, count]) => ({ name, count }))
    .sort((a, b) => b.count - a.count)
    .slice(0, 4); // Get top 4 products

  return (
    <BarChart
      width={394}
      height={290}
      data={productCountsArray}
      layout='vertical' // Horizontal bars
      margin={{ top: 10, right: 50, left: 0, bottom: 5 }}
      padding={{ top: 20 }}
    >
      <XAxis hide={true} type='number' domain={[0, 'dataMax']} />
      <YAxis
        type='category'
        dataKey='name'
        hide={true} // Hide the Y-axis itself
      />
      <Bar
        dataKey='count'
        fill='#5281CC'
        barSize={20}
        isAnimationActive={false} // Disable animation to correctly display bar labels
      >
        {/* Custom label for product type */}
        <LabelList dataKey='name' content={renderCustomLabel} />
        {/* Label for counts positioned at the end of each bar */}
        <LabelList dataKey='count' position='right' fill='#000' offset={8} />
      </Bar>
    </BarChart>
  );
};

ChartMostPurchasedProduct.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      line_items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          quantity: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired
};

export default ChartMostPurchasedProduct;
